import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../components/0-layout"
import SEO from "../components/seo"
import Val from "../components/0-val"

/**
 * @property {object} allRedirectJson
 * @property {object} redirects
 * @property {object} src
 * @property {object} dst
 */

const RedirectListPage = () => {
  const listOfRedirects = useStaticQuery(graphql`
      query RedirectQuery {
          allRedirectJson {
              edges {
                  node {
                      redirects {
                          src
                          dst
                      }
                      statics {
                          src
                          dst
                      }
                  }
              }
          }
      }
  `)

  const Table = styled.table`
    border-collapse: collapse;
    margin-bottom: ${Val.marginBase}px;

    & tr:nth-child(even) {
      background-color: #EEE;
    }
    
    & td {
      line-height: 2rem;
      padding: 5px 5px 13px 5px;
      
      & a:nth-child(1) {
        text-decoration: underline;
        color: indianred;
      }

      & a:nth-child(3) {
        text-decoration: underline;
        color: dodgerblue;
      }
    }
  `

  const Title = styled.h1`
    font-size: ${Val.fontSizeTitle}px;
    margin-bottom: ${Val.marginBase / 2}px;
`

  let tdsOfRedirects = [], tdsOfStatics = []
  let cnt = 1

  listOfRedirects.allRedirectJson.edges[0].node.redirects.forEach(i => {
    tdsOfRedirects.push(
      <tr>
        <td>
          No. {cnt} &nbsp;
        </td>

        <td>
          転送元：
        </td>

        <td>
          <a href={i.src}>{i.src}</a>
        </td>
      </tr>,
    )
    cnt += 1
  })

  let cntt = 1
  listOfRedirects.allRedirectJson.edges[1].node.statics.forEach(i => {
    tdsOfStatics.push(
      <tr>
        <td>
          No. {cntt} &nbsp;
        </td>

        <td>
          転送元：
        </td>

        <td>
          <a href={i.src}>{i.src}</a>
        </td>
      </tr>,
    )
    cntt += 1
  })

  return (
    <Layout sitePath={"redirect"} pageTitle="">

      <SEO title="tirepakr.jp 内の配信ファイル一覧、及び転送先"/>

      <p>&nbsp;</p>

      <Title>tirepakr.jp 内の配信ファイル一覧、及び転送先</Title>

      <p>&nbsp;</p>

      <Title>▶ URLs（転送のみ）</Title>

      <Table>
        {tdsOfRedirects}
      </Table>

      <p>&nbsp;</p>

      <Title>▶ URLs（転送、及び実ファイル収納先）</Title>

      <Table>
        {tdsOfStatics}
      </Table>

    </Layout>
  )
}

export default RedirectListPage
